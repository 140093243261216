import {useCallback, useEffect, useMemo, useState} from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {Form, Button, Typography, Input} from "antd";

import {
    addIssue,
    fetchAdminUsers, fetchClientAdminUsers,
    fetchRiderUsers,
    getDropdownVehicles,
    setModalName,
} from "store/actions";
import { RadioGroup, FormItem, SelectSearch } from "../components";
import { DatePicker } from "../components/base";
import { AppState } from "store";
import { parseUsers, parseVehicles } from "utils/parsers";
import { ADD_ISSUE_FORMIK } from "utils/formik-data";

interface ComponentProps {
  vehicleId?: string;
}

const { Title, Text } = Typography;

const metaData = {
  time_range: {
    name: "Time Range",
    options: [
      { label: "Accident", value: "1" },
      { label: "Breakdown", value: "4" },
      { label: "Roadside breakdown", value: "5"},
      { label: "Service", value: "2" },
      { label: "Vehicle missing", value: "3" },
      { label: "Unknown", value: "0" },
    ],
  },
};

const hostType = [{label: "Motorcycle", value: 1},{label: "Charging station", value: 2}]
const kioskIssueTypes = [
      { label: "Electrical failure", value: "1" },
      { label: "Fire or smoke", value: "2" },
      { label: "Temperature rise", value: "3"},
      { label: "Battery swap", value: "4" },
      { label: "Mechanical failure", value: "5" },
      { label: "Noise", value: "6" },
      { label: "Other", value: "0" },
    ]

const kioskList = [
  {label: "K1", value:"K1"},
  {label: "K2", value:"K2"}
];

function disabledDate(current: Date) {
  return current && current <= new Date();
}

function disabledOccurDate(current: Date) {
  return current && current >= new Date();
}

export default function AddIssuesForm(props: ComponentProps) {
  const { vehicleId } = props;
  const dispatch = useDispatch();
  const {
    vehicles: { dropdownVehicles },
    users: { riderUsers, adminUsers },
    common: {userType=1},
  } = useSelector<AppState, AppState>((state) => state);
  const [host, setHost] = useState(1);

  const vehiclesOptions = useMemo(() => parseVehicles(dropdownVehicles), [
    dropdownVehicles,
  ]);
  const usersOptions = useMemo(() => parseUsers(riderUsers), [riderUsers]);
  const adminOptions = useMemo(() => parseUsers(adminUsers), [adminUsers]);

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const onSubmit = useCallback(
    (values: typeof ADD_ISSUE_FORMIK.initialValues) => {
      dispatch(
        addIssue({
          assigneeId: values.assignee,
          type: Number(values.issue_type),
          vehicleId: values.vehicle_id,
          dueDate: values.due_date || new Date(),
          riderId: values.driver_name,
          issue_host_id: values.issue_host_id,
          kiosk_issue_type: Number(values.kiosk_issue_type),
          type_description: values.type_description.trim(),
          issue_host:values.issue_host,
          issue_occur_date: values.issue_occured || new Date(),
        })
      );
      onCancel();
    },
    [dispatch, onCancel]
  );

  const initialValues = useMemo(() => {
    return vehicleId
      ? {
          ...ADD_ISSUE_FORMIK.initialValues,
          vehicle_id: vehicleId,
        }
      : ADD_ISSUE_FORMIK.initialValues;
  }, [vehicleId]);

  useEffect(() => {
    if (!dropdownVehicles) {
      dispatch(getDropdownVehicles());
    }
    console.log("Admin users : ", adminUsers)
    if (!adminUsers) {
      dispatch(fetchRiderUsers());
      if (userType == 1 || userType == 2) {
          dispatch(fetchAdminUsers());
      } else {
          dispatch(fetchClientAdminUsers());
      }
    }
  }, [dispatch, dropdownVehicles, adminUsers]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ADD_ISSUE_FORMIK.validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        errors,
        isSubmitting,
        isValid,
      }) => (
        <div className="form-container">
          <Title level={5}>Add an Issue</Title>
          <Text className="description" type="secondary">
            Please provide the following details to add an issue
          </Text>
          <Form className="--issues-modal-form" onFinish={handleSubmit}>
            <FormItem
                verticalAlign="flex-start"
                error={errors.issue_type}
                label="Product"
                required
            >
              <RadioGroup
                  options={hostType}
                  value={values.issue_host}
                  onChange={(selected) => {
                    setFieldValue("issue_host", selected.target.value);setHost(selected.target.value);
                  }}
              />
            </FormItem>
            <FormItem
              verticalAlign="flex-start"
              error={errors.issue_type}
              label="Issue Type"
            >
              { host == 1 ? <RadioGroup
                options={metaData.time_range.options}
                value={values.issue_type}
                onChange={(selected) => {
                  setFieldValue("issue_type", selected.target.value);
                }}
              /> :
                  <RadioGroup
                      options={kioskIssueTypes}
                      value={values.kiosk_issue_type}
                      onChange={(selected) => {
                        setFieldValue("kiosk_issue_type", selected.target.value);
                      }}
                  />
              }
            </FormItem>
            {host == 1 && <FormItem error={errors.vehicle_id} label="Vehicle ID">
              <SelectSearch
                value={vehicleId || values.vehicle_id}
                items={vehiclesOptions}
                setFieldValue={setFieldValue}
                fieldName="vehicle_id"
                placeholder="Select Vehicle"
                type={errors.vehicle_id ? "error" : "success"}
                disabled={Boolean(vehicleId)}
              />
            </FormItem> }
            {host == 2 && <FormItem error={errors.issue_host_id} label="Host ID">
              <SelectSearch
                  value={values.issue_host_id}
                  items={kioskList}
                  setFieldValue={setFieldValue}
                  fieldName="issue_host_id"
                  placeholder="Select Kisok"
                  type={errors.issue_host_id ? "error" : "success"}
              />
            </FormItem> }
            <FormItem label="Issue Assignee" error={errors.assignee}>
              <SelectSearch
                value={values.assignee}
                items={adminOptions}
                setFieldValue={setFieldValue}
                fieldName="assignee"
                placeholder="Select Assignee"
              />
            </FormItem>
            {host == 1 && <FormItem label="Vehicle Rider" error={errors.driver_name}>
              <SelectSearch
                value={values.driver_name}
                items={usersOptions}
                setFieldValue={setFieldValue}
                fieldName="driver_name"
                placeholder="Select Rider"
              />
            </FormItem> }
            <FormItem label="Due Date">
              <DatePicker
                name="due_date"
                size="middle"
                value={undefined}
                placeholder="Select "
                onChange={(date) => setFieldValue("due_date", date)}
                className="due-date"
                format="DD MMM YYY"
                disabledDate={disabledDate}
              />
            </FormItem>
            <FormItem label="Issue Occurred">
              <DatePicker
                name="issue_occured"
                size="middle"
                value={values.issue_occured}
                placeholder="Select "
                className="due-date"
                onChange={(date) => setFieldValue("issue_occured", date)}
                format="DD MMM YYY"
                disabledDate={disabledOccurDate}
              />
            </FormItem>
            <FormItem label="Descripion">
              <Input.TextArea
                  name="type_description"
                  value={values.type_description}
                  placeholder=""
                  onChange={(type_description) => setFieldValue("type_description", type_description.target.value)}
                  style={{ minHeight: "60px"}}
              />
            </FormItem>
            <div className="action-btn-container">
              <Button className="cancel-btn" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isSubmitting || !isValid || !values.issue_type}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
