import { Select } from "antd";
import React, { useState, useEffect } from 'react';
import { SwitchButton } from "components/base";
import { useCallback } from "react";
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { setSelectedDashboard } from "store/actions";
import { customDropdownIcon, filledDropdownIcon } from "svgs";
import { DashboardState } from "types";
import { DASHBOARD_OPTIONS } from "utils/constants";
import { DatePicker, Form } from 'antd';
import FinancialWidget from 'components/charts/financial-widget'; 
import {initializeCustomerBilling, setTotalBill} from "../../store/actions/billing-actions";
import FinancialsTileContainer from 'components/tab-content/dashboard-content/tile-containers/financials-container'; 
const { Option } = Select;



export default function DashboardHeader() {
  const dispatch = useDispatch();
  const [{ meta }] = useCookies();

  const { selectedDashboard } = useSelector<AppState, AppState["dashboard"]>(
    (state) => state.dashboard
  );
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
   // Select the billing state from Redux
   const { totalBill, bill } = useSelector((state: AppState) => state.billing);


  const onChange = useCallback(
    (value: DashboardState["selectedDashboard"]) => {
      dispatch(setSelectedDashboard(value));
    },
    [dispatch]
  );

  const handleDateChange = (date: moment.Moment | null, dateString: string) => {
    if (date) {
    const formattedDate = date.format('YYYY-MM-DD'); 
    setSelectedDate(formattedDate);
    console.log('Selected Date:', formattedDate); 
    } else {
      setSelectedDate(null);
    }
  };
  useEffect(() => {
    if (meta?.user?.organizationId && selectedDate) {
      dispatch(initializeCustomerBilling({ 
        organization_id: meta.user.organizationId, 
        date: selectedDate 
      }));
      
    }
  }, [dispatch, meta, selectedDate]);

  return (
    <div className="common-header">
      {/* <Select
        value={selectedDashboard}
        bordered={false}
        dropdownClassName="select-dashboard-view"
        className="selected-label"
        suffixIcon={filledDropdownIcon}
        onChange={onChange}>
        {DASHBOARD_OPTIONS.map((option) => (
          <Option value={option.value}>{option.label}</Option>
        ))}
      </Select> */}
      <h1>Overview</h1>
      <div className="actions-container dashboard-header">
      <section>
      <Form.Item
        label="Month and Year:"
        name="datePicker"
        className="form-item">
        <DatePicker 
          picker="month"
          format="MMMM YYYY" 
          onChange={handleDateChange}
           />
      </Form.Item>  
    </section>
    <div></div>
    {/* <section style={{ marginRight: '40px' }}>
      <Form.Item
        label="To:"
        name="datePicker"
        className="form-item">
        <DatePicker />
      </Form.Item>
    </section> */}
        {/* <section>
          <span className="dashboard-header-label">Partner:</span>
          <Select
            defaultValue="All"
            suffixIcon={customDropdownIcon}
            className="dashboard-dropdown">
            <Option value="All">All</Option>
          </Select>
        </section> */}
        {/* <section>
          <span className="dashboard-header-label">Vehicle Type:</span>
          <Select
            defaultValue="All"
            suffixIcon={customDropdownIcon}
            className="dashboard-dropdown">
            <Option value="All">All</Option>
          </Select>
        </section> */}
        {/* <section>
          <span className="dashboard-header-label">Filter By:</span>
          <Select
            defaultValue="Last Week"
            suffixIcon={customDropdownIcon}
            className="dashboard-dropdown">
            <Option value="last week">Last Week</Option>
            <Option value="last month">Last Month</Option>
            <Option value="custom">Custom</Option>
          </Select>
        </section> */}
      
      </div>
    </div>
  );
}
