import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import * as yup from "yup";
import {string} from "yup";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const VEHICLE_LICENCE_REGEX = /^[A-Za-z0-9\w-]+$/;
const IMEI_REGEX = /^(?!\b(.)\1+\b)\d{15}$/gi;
export const wordRegex = /^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/;

export const YUP_MESSAGES = {
  issue_type: "Issue type is required",
  payment_type: "Payment type is required",
  amount: "Amount is required",
  number_amount: "Amount should be a number",
  year_err: "Year should be a number",
  payment_status: "Payment status is required",
  vehicle_id: "Vehicle ID is required",
  user_name: "Full name is required",
  valid_user_name: "Full name is not valid",
  phone_no: "Phone number is required",
  user_role: "User role is required",
  licence: "Please input your vehicle licence",
  licence_validation: "Special characters are not allowed",
  vehicle_model: "Please input vehicle model",
  document_limit: "The input should not exceed 20 digits ",
  email: "Email is required",
  email_invalid: "Please enter a valid email",
  vehicle: "Vehicle is required",
  lease_month: "Lease time is required",
  special_character: "Special characters are not allowed",
  client: "Client is required",
  phone_validation: "Phone number is invalid",
  phone_incomplete: "Enter complete phone number",
  date: "Date is required.",
  time: "Time is required.",
  vehicle_count: "Number of vehicles are required.",
  toll_gate: "Toll gate is required.",
  cause_of_fine: "Cause of fine is required.",
  workshop: "workshop is required.",
  imei_validation: "Need valid device id number",
  invoice: "Invoice is required.",
  firmware: "Firmware Version file is required",
  vehicle_mode: "Model name is required",
  model_make: "Model make name is required",
  model_year: "Model year is required",
  model_transmission: "Transmission is required",
  battery_id: "Battery ID is required",
  operational_state: "Operational state is required",
  battery_make: "Make is required",
  battery_model: "Model is required",
  battery_year: "Year is required",
  battery_model_id: "Battery model is required",
  organization_name: "Organization name is required",
  organization_type: "Organization type is required",
  organization_address: "Organization address is required",
  city: "City is required",
  state: "State is required",
  country: "Country is required",
  zone_name: "Zone name is required",
  vin: "VIN is required",
  vin_regx: "VIN is required",
  wordRegexErr: "please enter valid input",
  short_id: "Battery Alias is required",


};

export const ADD_ISSUE_FORMIK = {
  initialValues: {
    issue_type: "1",
    vehicle_id: "",
    driver_name: "",
    assignee: "",
    due_date: new Date(),
    issue_occured: new Date(),
    issue_status: 0,
    issue_host:1,
    issue_host_id : "",
    kiosk_issue_type: "1",
    type_description: "",
    issue_level: 0,
  },
  validationSchema: yup.object({
    issue_type: yup.string().optional(),
    vehicle_id: yup.string().optional(),
    driver_name: yup.string(),
    assignee: yup.string(),
    due_date: yup.date().optional(),
    issue_occured: yup.date().optional(),
    issue_status: yup.number().optional(),
    issue_host: yup.number().optional(),
    issue_host_id: yup.string().optional(),
    kiosk_issue_type: yup.string().optional(),
    type_description: yup.string().optional(),
    issue_level: yup.number().optional(),
  }),
};

export const ADD_PAYMENT_FORMIK = {
  initialValues: {
    payment_status: "paid",
    payment_type: "1",
    date: "",
    due_date: new Date(),
    time: "",
    client: "",
    reason_of_payment: "",
    amount: "",
    vehicle_count: "",
    toll_gate: "",
    cause_of_fine: "",
    workshop: "",
    fileList: undefined,
  },
  validationSchema: yup.object({
    payment_status: yup.string().optional(),
    payment_type: yup.string().required(YUP_MESSAGES.payment_type),
    date: yup.date().optional(),
    due_date: yup.date().optional(),
    amount: yup
      .string()
      .matches(/^[0-9 ]+$/, YUP_MESSAGES.number_amount)
      .required(YUP_MESSAGES.amount),
    time: yup.date().optional(),
    vehicle_count: yup.number().optional(),
    toll_gate: yup.string().optional(),
    client: yup.string().optional(),
    reason_of_payment: yup.string().optional(),
    cause_of_fine: yup.string().optional(),
    workshop: yup.string().optional(),
    fileList: yup.mixed<UploadFile<any>[]>().optional(),
  }),
};

export const EDIT_USER_FORMIK = {
  initialValues: {
    name: "",
    phone_no: "",
    user_role: "",
    email: "",
    document_id: "",
    licence_no: "",
  },
  validationSchema: yup.object({
    name: yup.string().required(YUP_MESSAGES.user_name),
    phone_no: yup.string().required(YUP_MESSAGES.phone_no),
    user_role: yup.string().required(YUP_MESSAGES.user_role),
    email: yup.string(),
    document_id: yup
      .string()
      .matches(/^[A-Za-z0-9 ]+$/, YUP_MESSAGES.licence_validation),
    licence_no: yup
      .string()
      .matches(/^[A-Za-z0-9 ]+$/, YUP_MESSAGES.licence_validation),
  }),
};

export const VEHICLE_FORMIK = {
  initialValues: {
    vehicle_id: "",
    vehicle_model: "",
    assign_to: [],
    client: "",
    zone: "",
    location: "",
    iot_id: "",
    vin: "",
    vehicle_service_status: 0,
  },
  validationSchema: yup.object({
    vehicle_id: yup
      .string()
      .required(YUP_MESSAGES.licence)
      .matches(VEHICLE_LICENCE_REGEX, YUP_MESSAGES.licence_validation),
    vehicle_model: yup.string().required(YUP_MESSAGES.vehicle_model),
    assign_to: yup.mixed<string[]>(),
    client: yup.string().optional(),
    zone: yup.string().optional(),
    // zone: yup.string(),
    location: yup.string().optional(),
    iot_id: yup
      .string()
      .optional()
      .matches(/^[A-Za-z0-9]+$/, YUP_MESSAGES.imei_validation),
    vin: yup.string().matches(/^[A-Za-z0-9]+$/, YUP_MESSAGES.vin_regx).required(YUP_MESSAGES.vin),
    vehicle_service_status: yup.number().optional(),
  }),
};

export const ZONE_FORMIK = {
  initialValues: {
    name: "",
    coordinates: [],
  },
  validationSchema: yup.object({

    name: yup.string().required(YUP_MESSAGES.zone_name),
    coordinates: yup.mixed<string[]>(),
  }),
};

export const VEHICLE_ORGANIZATION_ASSIGNMENT_FORMIK = {
  initialValues: {
    user_id: [],
    organization_id: "",
  },
  validationSchema: yup.object({
    user_id: yup.mixed<string[]>(),
    organization_id: yup.string().required(YUP_MESSAGES.organization_name)
  })
}

export const USER_FORMIK = {
  initialValues: {
    phone_no: "",
    dial_code: "",
    full_name: "",
    role: "",
    email: "",
    vehicle: [""],
    document_id: "",
    licence_no: "",
    document_file_raw: undefined,
    document_file: undefined,
    fileList: undefined,
    organization_id: "",
  },
  validationSchema: yup.object({
    phone_no: yup
      .string()
      .min(7, YUP_MESSAGES.phone_incomplete)
      .required(YUP_MESSAGES.phone_no),
    dial_code: yup.string(),
    full_name: yup.string().matches(/^[A-Za-z]+(?:\s+[A-Za-z]+)*\s*$/, YUP_MESSAGES.valid_user_name).required(YUP_MESSAGES.user_name),
    email: yup
      .string()
      .required(YUP_MESSAGES.email)
        .matches(
            /^[a-zA-Z0-9._+]*[a-zA-Z0-9][a-zA-Z0-9]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            YUP_MESSAGES.email_invalid
        ),
      // .matches(
      //   /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/,
      //   YUP_MESSAGES.email_invalid
      // ),
    role: yup.string().required(YUP_MESSAGES.user_role),
    vehicle: yup.mixed<string[]>().optional(),
    document_id: yup.string().max(20, YUP_MESSAGES.document_limit),
    licence_no: yup
      .string()
      .optional()
      .matches(/^[A-Za-z0-9 ]+$/, YUP_MESSAGES.licence_validation),
    document_file_raw: yup.mixed<RcFile>().optional(),
    fileList: yup.mixed<UploadFile<any>[]>().optional(),
    document_file: yup.mixed<Uint8Array>().optional(),
    organization_id: yup.string().optional(),
  }),
};

export const VEHICLE_MODEL_FORMIK = {
  initialValues: {
    model_id: "",
    make: "",
    model: "",
    year: '2024',
    transmission: "Automatic",
    document_file_raw: undefined,
    document_file: undefined,
    fileList: undefined,
    modelFiles: [],
  },
  validationSchema: yup.object({

    make: yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.model_make),
    model : yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.vehicle_model),
    year: yup.string().matches(/^(?!0000)\d{4}$/, YUP_MESSAGES.year_err).required(YUP_MESSAGES.model_year),
    transmission: yup.string().required(YUP_MESSAGES.model_transmission),
    document_file_raw: yup.mixed<RcFile>().optional(),
    fileList: yup.mixed<UploadFile<any>[]>().optional(),
    document_file: yup.mixed<Uint8Array>().optional(),
    modelFiles: yup.mixed<RcFile[]>().optional(),
  }),
};

export const BATTERY_FIRMWARE = {
  initialValues: {
    battery_id: "",
    soc_level: "",
    battery_health: "",
    operational_state: "",
    host_id: "",
    battery_model_id: "",
    make: "",
    model: "",
    year: 2023,
    client_org_id: "",
    battery_alias: "",
  },
  validationSchema: yup.object({
    battery_id: yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.battery_id),
    soc_level: yup.string().optional(),
    battery_health: yup.string().optional(),
    host_id: yup.string().optional(),
    battery_model_id: yup.string().required(YUP_MESSAGES.battery_model),
    operational_state: yup.string().optional(),
    make: yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).optional(),
    model : yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).optional(),
    year: yup.number().optional(),
    client_org_id: yup.string().optional(),
    battery_alias: yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.short_id),
  }),
}

export const ORGANIZATION_FIRMWARE = {
  initialValues: {
    name: "",
    street_address: "",
    city: "",
    state: "",
    country: "",
    org_type: "",
  },
  validationSchema: yup.object({
    name: yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.organization_name),
    street_address: yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.organization_address),
    city: yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.city),
    state: yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.state),
    country: yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.country),
    org_type: yup.string().required(YUP_MESSAGES.organization_type),

  }),
}

export const BATTERY_MODEL_FIRMWARE = {
  initialValues: {
    make: "",
    model: "",
    year: '2024',
  },
  validationSchema: yup.object({
    make: yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.battery_make),
    model : yup.string().matches(wordRegex, YUP_MESSAGES.wordRegexErr).required(YUP_MESSAGES.battery_model),
    year: yup.string().matches(/^(?!0000)\d{4}$/, YUP_MESSAGES.year_err).required(YUP_MESSAGES.battery_year),
  }),
}

export const CHANGE_MQTT_SERVER_FIRMWARE = {
  initialValues: {
    mqtt_server: "",
    port: "",
  },
  validationSchema: yup.object({
    mqtt_server: yup.string().required("server is required"),
    port: yup.string().matches(/^[0-9]+$/, YUP_MESSAGES.year_err).required("required valid port"),
  }),

}

export const FIRMWARE_FORMIK = {
  initialValues: {

    document_file_raw: undefined,
    document_file: undefined,
    fileList: undefined,
  },
  validationSchema: yup.object({
    document_file_raw: yup.mixed<RcFile>().optional(),
    fileList: yup.mixed<UploadFile<any>[]>().optional(),
    document_file: yup.mixed<Uint8Array>().optional(),
  }),
};

export const MODEL_FIRMWARE_FORMIK = {
  initialValues: {
    model_id: "",
    md_hash: "",
    firmware_version: "",
    document_file_raw: undefined,
    document_file: undefined,
    fileList: undefined,
  },
  validationSchema: yup.object({
    document_file_raw: yup.mixed<RcFile>().optional(),
    fileList: yup.mixed<UploadFile<any>[]>().optional(),
    document_file: yup.mixed<Uint8Array>().optional(),
    model_id: yup.string().optional(),
    firmware_version: yup.string().optional(),
    md_hash: yup.string().optional(),
  }),
};

export const SHAKEHAND_FORMIK = {
  initialValues: {
    lease_month: 2,
    model: "",
    document_file: undefined,
    phone_no: "",
    dial_code: "",
    email: "",
  },
  validationSchema: yup.object({
    lease_month: yup.number().optional(),
    model: yup.string().optional(),
    document_file: yup.mixed<Uint8Array>().optional(),
    dial_code: yup.string().optional(),
    phone_no: yup
      .string()
      .matches(phoneRegExp, YUP_MESSAGES.phone_validation)
      .required(YUP_MESSAGES.phone_no),
    email: yup.string().email().required(YUP_MESSAGES.email),
  }),
};

export const NEXTSTEP_ENQUIRY_FORMIK = {
  initialValues: {
    message: "",
  },
  validationSchema: yup.object({
    message: yup.string().optional(),
  }),
};

export const NEXTSTEP_DOCUMENT_FORMIK = {
  initialValues: {
    fileList: undefined,
  },
  validationSchema: yup.object({
    fileList: yup.mixed<UploadFile<any>[]>().optional(),
  }),
};
