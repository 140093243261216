import { TableProps } from "antd/lib/table";
import { TileSparkline } from "components";
import { Table } from "components/base";
import {useCallback, useState, useMemo , useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {
  FINANCIALS_WIDGET,
} from "components/tab-content/dashboard-content/__mock__";
import {parseBillingFieldsTable} from "../../utils/parsers/billing-parser";
import {initializeCustomerBilling} from "../../store/actions";
import {useCookies} from "react-cookie";

interface ComponentProps {
  name: string;
  selectedDate: string;
}

const columns: TableProps<any>["columns"] = [
  { title: "Name", dataIndex: "name" },
  // { title: "Count", dataIndex: "count", align: "center" },
  { title: "Value", dataIndex: "value", align: "right" },
];

// const data = [
//   { key: 0, name: "Paid", count: "80", value: "3,000 AED" },
//   { key: 1, name: "outstanding", count: "20", value: "1,200 AED" },
// ];


// const {
//   dashboard: {dashboardValues},
// } = useSelector<AppState, AppState>((state) => state);


// const dashboardValue = useMemo(() =>  parseDashboardValues(dashboardValues), [
//   dashboardValues,
// ]);


export default function FinancialWidget(props: ComponentProps) {
  const [{ meta }] = useCookies();
  const { name, selectedDate } = props;

  // let data = FINANCIALS_WIDGET[name];
  let data =  {
      title: "Total Bill",
      value: "PKR 0",
      chart:[],
      footer: "For Month of ",
      due_date_tag: "Due Date: ",
      due_date: "28 August 2024",
      totalValue: "August 2024",
      table: [
          { key: 0, name: "Subtotal", value: "PKR 4,500" },
          { key: 1, name: "Tax", value: "PKR 9,500" },
      ],
  }
  const dispatch = useDispatch();

  const {
    bill,
      totalBill
  } = useSelector<AppState, AppState["billing"]>((state) => state.billing);
  const {
    organizationId
  } = useSelector<AppState, AppState["common"]>((state) => state.common);
  const billData = useMemo(
      () => parseBillingFieldsTable(bill),
      [bill]
  );
  useEffect(() => {
    const user = meta?.user
    dispatch(initializeCustomerBilling({organization_id:user.organizationId, date:selectedDate}));
  }, [dispatch,totalBill]);
  if (totalBill) {
    data.value = totalBill
  }
  let newDate = new Date()

  if(billData) {
      data.table[0].value =  "PKR " + billData.subTotal 
      data.table[1].value =  "PKR " + billData.tax 
      data.totalValue = billData.monthYear || newDate.toLocaleString('default', { month: 'long' })+", "+ newDate.getFullYear()
      data.due_date = billData.dueDate || "--/--/----"
  }else{
    data.table[0].value =  "PKR 0" 
      data.table[1].value =  "PKR 0"  
      data.totalValue = ""
      data.due_date =  "--/--/----"
  }
  return (
    <section className="financial-widget">
      <div className="summary" >
      <p className="title">
          {data.footer} <span className="total-value" style={{float: 'unset',fontSize: "16px"}}>{data.totalValue}</span>
        </p>
   <br></br>
        <Table
          customClasses="--simple-table"
          showHeader={false}
          dataSource={data.table}
          columns={columns}
          pagination={false}
          bordered
        />
             <div className="chart-container">
          <div className="chart">
            <TileSparkline className="" data={data.chart} />
          </div>
        </div>
       
      </div>
      <div className="detail">
       
          <p className="footer">
          <strong>{data.title}</strong>
        </p>
          <h1 className="value">{data.value}</h1>
          <p className="title">
          {data.due_date_tag} <span className="total-value" style={{float: 'unset',fontWeight: "bold"}}>{data.due_date}</span>
        </p>
      </div>
    </section>
  );
}
