import { combineReducers } from "redux";
import logs from './vehicles-reducer'
import vehicles from "./vehicles-reducer";
import firmwares from "./vehicles-reducer";
import common from "./common-reducer";
import issues from "./issues-reducer";
import users from "./users-reducer";
import dashboard from "./dashboard-reducer";
import financials from "./financials-reducer";
import clients from "./clients-reducer";
import zones from "./zones-reducer";
import models from "./vehicle-model-reducer";
import batteries from "./battery-reducer";
import batteryModels from "./battery-model-reducer";
import organizations from "./clients-reducer";
import notifications from "./fcm_notification_reducers";
import notificationCenter from "./notification-reducer";
import battery_heartbeats from "./battery_hb_reducer";
import map_marker from "./map-marker-reducer"
import billing from "./billing-reducer"
import riderStats from "./users-reducer"


export default combineReducers({
  users,
  issues,
  common,
  vehicles,
  firmwares,
  logs,
  dashboard,
  financials,
  clients,
  zones,
  models,
  batteries,
  batteryModels,
  organizations,
  notifications,
  battery_heartbeats,
  map_marker,
  billing,
  notificationCenter,
  riderStats,
});

