import { getRandomString, range } from "utils/helpers";

export const unassignedVehiclesData = range(50).map((item, index) => ({
  key: index,
  vehicle_no: getRandomString([
    "D - 34677",
    "J - 37829",
    "D - 27825",
    "J - 64564",
    "D - 34662",
  ]),

  make_model: getRandomString([
    "Honda 150 cc ",
    "Honda 160 cc ",
    "TVS 160 cc",
    "TVS 180 cc",
    "Bajaj 180 cc",
  ]),
}));

export const unresolvedissuesData = range(5).map((item, index) => ({
  key: index,
  issue_id: getRandomString(["1124", "2214", "2344", "3332", "3345"]),
  issue_type: getRandomString([
    "Accident",
    "Breakdown",
    "Service",
    "Vehicle missing",
    "Other",
  ]),
  vehicle_id: getRandomString([
    "D - 34677",
    "J - 37829",
    "D - 27825",
    "J - 64564",
    "D - 34662",
  ]),
}));
